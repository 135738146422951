import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
  styled,
} from "@mui/material";
import logo from "./../assets/logo.png";
import cookie from "./../assets/cookie.png";
import { VisibilityContext } from "../contexts/visibilityContext";
import { useFetchConfig } from "../hooks/useFetchConfig";
import { CORPORATIVE_WEBSITE } from "../constants/constants";
import { isAccessor } from "typescript";

const StyledCard = styled(Card)(({ theme }: any) => ({
  position: "fixed",
  zIndex: 1000,
  boxShadow: "0 0 15px 8px rgba(0, 0, 0, 0.15)",
}));

const StyledCardHeader = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  gap: theme.spacing(1),
}));

const StyledCardFooter = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  gap: theme.spacing(1),
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  borderRadius: "0 0 4px 4px",
}));

const StyledDivider = styled("div")(({ theme }: any) => ({
  height: 1,
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  margin: theme.spacing(0, 5, 0, 3),
}));

export const Popover = () => {
  const { popoverVisibility, setPopoverVisibility } =
    React.useContext(VisibilityContext);
  const { setConfigMenuVisibility } = React.useContext(VisibilityContext);

  const {
    config,
    fetchConfig,
    setConfig,
    rejectAllConfigCookies,
    acceptAllConfigCookies,
    trigger,
    getCurrentCookies,
    deleteSystemCookie,
    deleteAllSystemCookies,
    getConfigCookie,
    setConfigCookie,
    createDefaultControlCookie,
    deleteSystemCookiesByCategory,
    deleteCookiesByControlCookie,
    acceptAllCookies,
    detectNotCategorizedCookies,
    updateFromControlCookie,
    rejectAllSystemCookies,
    isAccepted,
  } = useFetchConfig();

  React.useEffect(() => {
    updateFromControlCookie();
  }, [config]);

  React.useEffect(() => {
    if (isAccepted === true) {
      setPopoverVisibility(false);
    }
  }, [isAccepted]);

  React.useEffect(() => {
    if (config.category_data && isAccepted === false) {
      setPopoverVisibility(true);
    }
  }, [config, isAccepted]);

  const handleAccept = () => {
    setPopoverVisibility(false);
    acceptAllCookies();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const popover = () => {
    return (
      popoverVisibility && (
        <div>
          <StyledCard
            sx={{
              width: {
                xs: "100%",
                sm: "400px",
              },
              bottom: {
                xs: "0px",
                sm: "10px",
              },
              right: {
                xs: "0px",
                sm: "10px",
              },
              borderRadius: {
                xs: "0px",
                sm: "4px",
              },
              border: {
                xs: "none",
                sm: "1px solid rgba(0, 0, 0, 0.2)",
              },
              borderTop: {
                xs: "1px solid rgba(0, 0, 0, 0.15)",
                sm: "none",
              },
            }}
          >
            <StyledCardHeader>
              <Avatar
                src={cookie}
                alt="cookie"
                sx={{
                  marginRight: "5px",
                }}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    transform: "translateY(5px)",
                    marginBottom: "10px",
                    lineHeight: "1.5",
                    color: "#534600",
                  }}
                >
                  {"Utilizamos cookies para mejorar tu experiencia"}
                </Typography>
                <Link
                  href={config.config_modal?.info_url}
                  sx={{
                    fontWeight: "bold",
                    color: "#046e22",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="body2"
                    color={"primary"}
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {"Ver la política de cookies"}
                  </Typography>
                </Link>
              </div>
            </StyledCardHeader>
            <StyledDivider />
            <CardContent>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                {config.popover_data?.description}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "flex-end",
                marginBottom: "10px",
                marginTop: "-10px",
                marginRight: "15px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setConfigMenuVisibility(true);
                }}
              >
                Personalizar
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleAccept();
                }}
              >
                Aceptar
              </Button>
            </CardActions>
            {config.config_modal?.cc_reference && (
              <StyledCardFooter>
                {/*  a div with a watermark */}

                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Desarrollado con{" "}
                  <Link
                    href={CORPORATIVE_WEBSITE}
                    sx={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontDecoration: "none",
                    }}
                  >
                    Cookie-control
                  </Link>
                </Typography>
              </StyledCardFooter>
            )}
          </StyledCard>
        </div>
      )
    );
  };

  const totalDocumentHeight = document.documentElement.scrollHeight;

  return (
    <>
      {config.config_modal?.block_background === true ? (
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            width: "100%",
            height: totalDocumentHeight,
            minHeight: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            top: 0,
            left: 0,
          }}
        >
          <>{popover()}</>
        </Box>
      ) : (
        <>{popover()}</>
      )}
    </>
  );
};
