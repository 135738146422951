import React, { useEffect } from "react";

import "./App.css";

import { Popover } from "./components/Popover";
import { FloatingButton } from "./components/FloatingButton";
import { ConfigMenu } from "./components/ConfigMenu";
import { VisibilityProvider } from "./contexts/visibilityContext";
import { useThemeContext } from "./hooks/useThemeContext";
import { useFetchConfig } from "./hooks/useFetchConfig";
import { PaletteColor, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

function App() {
  const { isDarkMode, setAutoDarkMode } = useThemeContext();
  const { config } = useFetchConfig();
  const [theme, setTheme] = React.useState({});

  useEffect(() => {
    config.config_modal?.auto_dark_mode === true
      ? setAutoDarkMode(true)
      : setAutoDarkMode(false);
  }, [config]);

  useEffect(() => {
    if (config) {
      const newTheme = createTheme({
        palette: {
          mode: isDarkMode ? "dark" : "light",
          primary: {
            main: "#046e22",
          },
          secondary: {
            main: "#fbe363",
          },
          /*   secondary: {
              main: config.secondary_color as PaletteColor["main"],
            }, */
        },
      });
      setTheme(newTheme);
    }
  }, [config, isDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <VisibilityProvider>
        <Popover />
        <FloatingButton />
        <ConfigMenu />
      </VisibilityProvider>
    </ThemeProvider>
  );
}

export default App;
