
import { ConfigModalModel } from "../models/ConfigModalModel";
import { ControlCookieValue } from "../models/types/ControlCookieValue";
import { CookieModel } from "../models/CookieModel";
import { useEffect } from "react";

import { CONTROL_COOKIE_NAME, FAMOUS_DOMAINS } from "../constants/constants";
import { CategoriesEnum } from "../models/enums/CategoriesEnum";
import { config } from "process";

export const useSystemCookies = () => {

    const getCurrentCookies = (): CookieModel[] => {
        const cookies = document.cookie.split(';');
        const cookiesModel: CookieModel[] = [];
        cookies.forEach(cookie => {
            const cookieModel = {
                name: cookie.split('=')[0].trim(),
                value: cookie.split('=')[1],
                expires: cookie.split(';').find(c => c.trim().startsWith('expires'))?.split('=')[1] || '' as any
            }
            cookiesModel.push(cookieModel);
        });
        return cookiesModel;
    }

    const deleteSystemCookie = (name: string) => {

        FAMOUS_DOMAINS.forEach(domain => {
            document.cookie = (`${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`).trim();
        });
    }


    const deleteAllSystemCookies = () => {
        const cookies = getCurrentCookies();
        cookies.forEach(cookie => {
            deleteSystemCookie(cookie.name as string);
        });
    }

    const getSystemConfigCookie = (): ControlCookieValue => {
        const configCookie = getCurrentCookies().find(c => c.name === CONTROL_COOKIE_NAME);
        if (configCookie) {
            return {
                ...JSON.parse(configCookie.value as string)
            }
        }
        return {
            accepted: false,
            essential: true,
            analytics: false,
            marketing: false,
            functional: false,
            other: false
        }
    }

    const setConfigCookie = (config?: ControlCookieValue) => {
        const expireDate = new Date();
        expireDate.setMonth(expireDate.getMonth() + 3);
        document.cookie = (`${CONTROL_COOKIE_NAME}=${JSON.stringify(config)}; expires=${expireDate.toUTCString()}; path=/`).trim();
    }

    const createDefaultControlCookie = () => {
        setConfigCookie({
            accepted: false,
            essential: true,
            analytics: false,
            marketing: false,
            functional: false,
            other: false
        });
    }

    const deleteSystemCookiesByCategory = (category: CategoriesEnum, cookieList: CookieModel[]) => {
        const cookies = getCurrentCookies();
        const cookiesToDelete = cookies.filter(c => cookieList.find(cc => cc.name === c.name));
        cookiesToDelete.forEach(cookie => {
            deleteSystemCookie(cookie.name as string);
        });
    }


    const deleteCookiesByControlCookie = (cookieList: CookieModel[]) => {
        const controlCookie = getSystemConfigCookie();

        const analyticsCookieList = cookieList.filter(c => c.category === CategoriesEnum.ANALYTICS);
        const marketingCookieList = cookieList.filter(c => c.category === CategoriesEnum.MARKETING);
        const functionalCookieList = cookieList.filter(c => c.category === CategoriesEnum.FUNCTIONAL);
        const otherCookieList = cookieList.filter(c => c.category === CategoriesEnum.OTHER);

        const cookiesToDelete: CookieModel[] = [];


        if (!controlCookie.analytics) {
            cookiesToDelete.push(...analyticsCookieList);
        }
        if (!controlCookie.marketing) {
            cookiesToDelete.push(...marketingCookieList);
        }
        if (!controlCookie.functional) {
            cookiesToDelete.push(...functionalCookieList);
        }
        if (!controlCookie.other) {
            cookiesToDelete.push(...otherCookieList);
        }

        const notCategorizedCookies = detectNotCategorizedCookies(cookieList);

        cookiesToDelete.push(...notCategorizedCookies);

        cookiesToDelete.forEach(cookie => {

            deleteSystemCookie(cookie.name as string);
        });

    }


    const acceptAllCookies = () => {
        const controlCookie = getSystemConfigCookie();
        controlCookie.accepted = true;
        controlCookie.essential = true;
        controlCookie.analytics = true;
        controlCookie.marketing = true;
        controlCookie.functional = true;
        controlCookie.other = true;
        setConfigCookie(controlCookie);
    }

    const rejectAllSystemCookies = () => {
        const controlCookie = getSystemConfigCookie();
        controlCookie.accepted = true;
        controlCookie.essential = true;
        controlCookie.analytics = false;
        controlCookie.marketing = false;
        controlCookie.functional = false;
        controlCookie.other = false;
        setConfigCookie(controlCookie);
    }


    const detectNotCategorizedCookies = (cookieList: CookieModel[]) => {

        const configCookies = cookieList.filter(c => c.category === CategoriesEnum.ESSENTIAL || c.category === CategoriesEnum.ANALYTICS || c.category === CategoriesEnum.MARKETING || c.category === CategoriesEnum.FUNCTIONAL || c.category === CategoriesEnum.OTHER);
        const notCategorizedCookies: CookieModel[] = [];
        if (cookieList.length === 0) return []
        getCurrentCookies().forEach(cookie => {
            if (configCookies.find(c => c.name === cookie.name)) {
                return;
            }
            notCategorizedCookies.push(cookie);
        });
        return notCategorizedCookies;
    }




    return {
        getCurrentCookies,
        deleteSystemCookie,
        deleteAllSystemCookies,
        getSystemConfigCookie,
        setConfigCookie,
        createDefaultControlCookie,
        deleteSystemCookiesByCategory,
        deleteCookiesByControlCookie,
        acceptAllCookies,
        detectNotCategorizedCookies,
        rejectAllSystemCookies,
    }
}
