import { Accordion, AccordionSummary } from "@mui/material";
import { useFetchConfig } from "../hooks/useFetchConfig";
import { CategoriesEnum } from "../models/enums/CategoriesEnum";
import { Typography } from "@mui/material";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { IOSSwitch } from "./IOSSwitch";
import { AccordionDetails } from "@mui/material";
import { TableContainer } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSystemCookies } from "../hooks/useSystemCookies";
import { useEffect, useState } from "react";
import { CookieModel } from "../models/CookieModel";
import { ControlCookieValue } from "../models/types/ControlCookieValue";
import { CategoryDataModel } from "../models/CategoryDataModel";

export const CategoryAccordion = ({
  category,
  categoriesInfo,
}: {
  category: CategoriesEnum;
  categoriesInfo: CategoryDataModel[];
}) => {
  const {
    config,
    fetchConfig,
    setConfig,
    rejectAllConfigCookies,
    acceptAllConfigCookies,
    updateFromControlCookie,
    trigger,
    getCurrentCookies,
    deleteSystemCookie,
    deleteAllSystemCookies,
    setConfigCookie,
    createDefaultControlCookie,
    deleteSystemCookiesByCategory,
    deleteCookiesByControlCookie,
    acceptAllCookies,
    detectNotCategorizedCookies,
    rejectAllSystemCookies,
    setFunctionalAllowed,
    setMarketingAllowed,
    setAnalyticsAllowed,
    setEssentialAllowed,
    isFunctionalAllowed,
    isMarketingAllowed,
    isAnalyticsAllowed,
    isEssentialAllowed,
    isOtherAllowed,
    setOtherAllowed,
    setAccepted,
    isAccepted,
    getCookiesByCategory,
  } = useFetchConfig();

  const isEssential = () => {
    return category === CategoriesEnum.ESSENTIAL;
  };

  const getCategoryState = () => {
    if (category === CategoriesEnum.ESSENTIAL) {
      return isEssentialAllowed;
    } else if (category === CategoriesEnum.ANALYTICS) {
      return isAnalyticsAllowed;
    } else if (category === CategoriesEnum.MARKETING) {
      return isMarketingAllowed;
    } else if (category === CategoriesEnum.FUNCTIONAL) {
      return isFunctionalAllowed;
    } else if (category === CategoriesEnum.OTHER) {
      return isOtherAllowed;
    }
  };

  const [default_checked, setDefaultChecked] = useState(getCategoryState());
  const [disabled, setDisabled] = useState(!isEssential());

  useEffect(() => {
    if (category === CategoriesEnum.ESSENTIAL) {
      setDefaultChecked(true);
      setDisabled(true);
    } else if (category === CategoriesEnum.ANALYTICS) {
      setDefaultChecked(isAnalyticsAllowed);
      setDisabled(false);
    } else if (category === CategoriesEnum.MARKETING) {
      setDefaultChecked(isMarketingAllowed);
      setDisabled(false);
    } else if (category === CategoriesEnum.FUNCTIONAL) {
      setDefaultChecked(isFunctionalAllowed);
      setDisabled(false);
    } else if (category === CategoriesEnum.OTHER) {
      setDefaultChecked(isOtherAllowed);
      setDisabled(false);
    }
  }, [category]);

  const hadleToggle = (checked: boolean) => {
    if (category === CategoriesEnum.ESSENTIAL) {
      setEssentialAllowed(checked);
      setDefaultChecked(checked);
    } else if (category === CategoriesEnum.ANALYTICS) {
      setAnalyticsAllowed(checked);
      setDefaultChecked(checked);
    } else if (category === CategoriesEnum.MARKETING) {
      setMarketingAllowed(checked);
      setDefaultChecked(checked);
    } else if (category === CategoriesEnum.FUNCTIONAL) {
      setFunctionalAllowed(checked);
      setDefaultChecked(checked);
    } else if (category === CategoriesEnum.OTHER) {
      setOtherAllowed(checked);
    }
  };

  const getInfoOfCurrentCategory = () => {
    return categoriesInfo.find(
      (categoryInfo) => categoryInfo.category === category
    );
  };

  return (
    <>
      <Accordion
        sx={{
          boxShadow: "none",
          border: "none",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            boxShadow: "none",
            border: "none",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            marginTop: "10px",
            borderRadius: "8px",
          }}
        >
          <FormGroup
            sx={{
              marginLeft: "10px",
            }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  defaultChecked={default_checked}
                  disabled={disabled}
                  onClick={hadleToggle}
                  name="checkedB"
                />
              }
              label=""
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </FormGroup>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              width: "calc(100% - 50px)",
            }}
            color={"text.secondary"}
          >
            {getInfoOfCurrentCategory()?.title || "No title"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color={"text.secondary"}>
            {getInfoOfCurrentCategory()?.description || "No description"}
          </Typography>
          <TableContainer
            sx={{
              marginVertical: "10px",
            }}
          >
            <Table
              aria-label="simple table"
              sx={{
                marginTop: "10px",
              }}
            >
              <TableBody>
                {getCookiesByCategory(category)?.map((row, index) => (
                  <>
                    <TableRow
                      key={index + "-name"}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.05)",
                        },
                      }}
                    >
                      <TableCell component="td" scope="row">
                        <Typography
                          variant="body2"
                          color={"text.secondary"}
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Nombre"}
                        </Typography>
                      </TableCell>

                      <TableCell
                        component="td"
                        scope="row"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {row.name}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="td" scope="row">
                        <Typography
                          variant="body2"
                          color={"text.secondary"}
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {getInfoOfCurrentCategory()?.domainSynomym}
                        </Typography>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {row.domain}
                      </TableCell>
                    </TableRow>
                    {row.expiry && (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          <Typography
                            variant="body2"
                            color={"text.secondary"}
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {getInfoOfCurrentCategory()?.expirationSynomym}
                          </Typography>
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row.expiry}
                        </TableCell>
                      </TableRow>
                    )}
                    {/* {row.description && (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          <Typography
                            variant="body2"
                            color={"text.secondary"}
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {getInfoOfCurrentCategory()?.descriptionSynomym}
                          </Typography>
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row.description[0].description}
                        </TableCell>
                      </TableRow>
                    )} */}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
