import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import logo from "./../assets/logo.png";
import cookie from "./../assets/cookie.png";
import {
  Typography,
  Link,
  Avatar,
  styled,
  IconButton,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Table,
  FormGroup,
  FormControlLabel,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { IOSSwitch } from "./IOSSwitch";
import { VisibilityContext } from "../contexts/visibilityContext";
import { useFetchConfig } from "../hooks/useFetchConfig";
import { ConfigModalModel } from "../models/ConfigModalModel";
import { ConfigModalModelData } from "../models/ConfigModalDataModel";
import { useSystemCookies } from "../hooks/useSystemCookies";
import { CategoryAccordion } from "./CategoryAccordion";
import { CategoriesEnum } from "../models/enums/CategoriesEnum";
import { CategoryDataModel } from "../models/CategoryDataModel";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenuHeader = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const StyledDivider = styled("div")(({ theme }: any) => ({
  height: 1,
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  margin: theme.spacing(1, 5, 0, 3),
}));

const StyledConfigFooter = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  gap: theme.spacing(1),
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  borderRadius: "0 0 4px 4px",
}));

export const ConfigMenu = (props: any) => {
  const { configMenuVisibility, setConfigMenuVisibility } =
    React.useContext(VisibilityContext);
  const { setPopoverVisibility } = React.useContext(VisibilityContext);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [localConfig, setLocalConfig] = React.useState<ConfigModalModel>(
    null as any
  );
  const {
    config,
    fetchConfig,
    setConfig,
    rejectAllConfigCookies,
    acceptAllConfigCookies,
    updateFromControlCookie,
    trigger,
    getCurrentCookies,
    deleteSystemCookie,
    deleteAllSystemCookies,
    getConfigCookie,
    setConfigCookie,
    createDefaultControlCookie,
    deleteSystemCookiesByCategory,
    deleteCookiesByControlCookie,
    acceptAllCookies,
    detectNotCategorizedCookies,
    updateConfigCookieFromControlCookieValue,
    rejectAllSystemCookies,
    setFunctionalAllowed,
    setMarketingAllowed,
    setAnalyticsAllowed,
    setEssentialAllowed,
    setOtherAllowed,
    setAccepted,
  } = useFetchConfig();

  const detectXs = () => {
    if (window.innerWidth < 800) {
      setFullScreen(true);
    } else {
      setFullScreen(false);
    }
  };

  useEffect(() => {
    detectXs();
    window.addEventListener("resize", detectXs);
  }, []);

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (configMenuVisibility) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [configMenuVisibility]);

  const handleClose = () => {
    setConfigMenuVisibility(false);
  };

  const handleAcceptAll = () => {
    setAccepted(true);
    setFunctionalAllowed(true);
    setMarketingAllowed(true);
    setAnalyticsAllowed(true);
    setOtherAllowed(true);

    acceptAllCookies();
    setConfigMenuVisibility(false);
    setPopoverVisibility(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleAccept = () => {
    setAccepted(true);
    setConfigMenuVisibility(false);
    setPopoverVisibility(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleReject = () => {
    setAccepted(true);
    setFunctionalAllowed(true);
    setMarketingAllowed(true);
    setAnalyticsAllowed(true);
    setOtherAllowed(true);
    rejectAllConfigCookies();
    setConfigMenuVisibility(false);
    setPopoverVisibility(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      {configMenuVisibility && (
        <div>
          <Dialog
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            open={configMenuVisibility}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "content-box",
            }}
          >
            <DialogTitle id="scroll-dialog-title">
              <StyledMenuHeader>
                <Avatar
                  src={cookie}
                  sx={{
                    marginRight: "5px",
                  }}
                />
                <div
                  style={{
                    width: "calc(100% - 50px)",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      transform: "translateY(5px)",
                      marginBottom: "10px",
                      lineHeight: "1.5",
                      color: "#534600",
                    }}
                  >
                    {"Gestión de cookies"}
                  </Typography>
                  <Link
                    href={config.config_modal?.info_url}
                    sx={{
                      fontWeight: "bold",
                      color: "#046e22",
                      textDecoration: "none",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={"primary"}
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Ver la política de cookies"}
                    </Typography>
                  </Link>
                </div>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </StyledMenuHeader>
              <StyledDivider />
            </DialogTitle>
            <DialogContent
              sx={{
                /* width */
                "::-webkit-scrollbar": {
                  width: "0.4em",
                },
                /* Handle */
                "::-webkit-scrollbar-thumb": {
                  background: "rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <DialogContentText
                id="scroll-dialog-dneccessaryescription"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {
                  "En este sitio web utilizamos cookies propias y de terceros para mejorar tu experiencia de navegación, analizar el uso de nuestros servicios y mostrarte publicidad relacionada con tus preferencias."
                }
              </DialogContentText>
              <CategoryAccordion
                category={CategoriesEnum.ESSENTIAL}
                categoriesInfo={config?.category_data as CategoryDataModel[]}
              />
              {/* <CategoryAccordion
                category={CategoriesEnum.FUNCTIONAL}
                categoriesInfo={config?.category_data as CategoryDataModel[]}
              /> */}
              <CategoryAccordion
                category={CategoriesEnum.ANALYTICS}
                categoriesInfo={config?.category_data as CategoryDataModel[]}
              />
              <CategoryAccordion
                category={CategoriesEnum.MARKETING}
                categoriesInfo={config?.category_data as CategoryDataModel[]}
              />
              {/* <CategoryAccordion
                category={CategoriesEnum.OTHER}
                categoriesInfo={config?.category_data as CategoryDataModel[]}
              /> */}
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "flex-end",
                marginBottom: "10px",
                marginTop: "10px",
                margin: "10px 10px 10px 10px",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Button
                color="primary"
                onClick={handleReject}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  marginBottom: {
                    xs: "5px",
                    sm: "0px",
                  },
                }}
                variant="outlined"
              >
                Rechazar todo
              </Button>
              <Button
                color="primary"
                onClick={handleAccept}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  marginBottom: {
                    xs: "5px",
                    sm: "0px",
                  },
                  transform: {
                    xs: "translateX(-4px)",
                    sm: "translateX(0px)",
                  },
                }}
                variant="outlined"
              >
                Guardar cambios
              </Button>
              <Button
                color="primary"
                onClick={handleAcceptAll}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  marginBottom: {
                    xs: "5px",
                    sm: "0px",
                  },
                  transform: {
                    xs: "translateX(-4px)",
                    sm: "translateX(0px)",
                  },
                }}
                variant="contained"
              >
                Aceptar todo
              </Button>
            </DialogActions>
            {config.config_modal?.cc_reference && (
              <StyledConfigFooter>
                {/*  a div with a watermark */}

                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Desarrollado con{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontDecoration: "none",
                    }}
                  >
                    Cookie-control
                  </Link>
                </Typography>
              </StyledConfigFooter>
            )}
          </Dialog>
        </div>
      )}
    </>
  );
};
