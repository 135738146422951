import { IconButton, styled } from "@mui/material";
import { VisibilityContext } from "../contexts/visibilityContext";
import React from "react";
import Tooltip from "@mui/material/Tooltip";

const StyledButton = styled(IconButton)(({ theme }: any) => ({
  position: "fixed",
  zIndex: 1000,
}));

const StyledImg = styled("img")(({ theme }: any) => ({
  height: "70px",
  objectFit: "cover",
  dropShadow: "2px 2px 5px rgba(0, 0, 0, 0.30)",
}));
export const FloatingButton = () => {
  const { floatingButtonVisibility } = React.useContext(VisibilityContext);
  const { setConfigMenuVisibility } = React.useContext(VisibilityContext);

  return (
    <>
      {floatingButtonVisibility && (
        <Tooltip
          title="Cookies"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#534600",
                "& .MuiTooltip-arrow": {
                  color: "#534600",
                },
              },
            },
          }}
        >
          <StyledButton
            onClick={() => setConfigMenuVisibility(true)}
            sx={{
              transition: "transform 0.3s ease-in-out",
              bottom: {
                xs: "1%",
                sm: "1%",
              },
              left: {
                xs: "3%",
                sm: "1%",
              },
            }}
          >
            <StyledImg src={"https://i.imgur.com/bOK1tDD.png"} alt="logo" />
          </StyledButton>
        </Tooltip>
      )}
    </>
  );
};
