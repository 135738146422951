import { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext({
    isDarkMode: false,
    setIsDarkMode: (value: boolean) => { },
    autoDarkMode: true,
    setAutoDarkMode: (value: boolean) => { },

});

export const ThemeContextProvider = ({ children }: any) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [autoDarkMode, setAutoDarkMode] = useState(false);

    const value = {
        isDarkMode,
        setIsDarkMode,
        autoDarkMode,
        setAutoDarkMode
    };

    useEffect(() => {
        if (!autoDarkMode) return;
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(mediaQuery.matches);
        const handler = (event: MediaQueryListEvent) => setIsDarkMode(event.matches);
        mediaQuery.addEventListener('change', handler);
        return () => mediaQuery.removeEventListener('change', handler);
    } ,[autoDarkMode])

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
};
