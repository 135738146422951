import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { styled } from '@mui/material';
import { FetchConfigProvider } from './contexts/FetchConfigContext';
import { ThemeContextProvider } from './contexts/ThemeContext';



//create cookieControl element in body

const body = document.getElementsByTagName('body')[0];
//apend cookieControl element to body
const cookieControl = document.createElement('div');
body.appendChild(cookieControl);
cookieControl.style.zIndex = "99999999";
const root = ReactDOM.createRoot(
  cookieControl as HTMLElement

);

const StyledApp = styled(App)(({ theme }: any) => ({
  fontFamily: "Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,    sans-serif",
  '&::-webkit-font-smoothing': "antialiased",
  '&::-moz-osx-font-smoothing': "grayscale"

}));



root.render(
  <React.StrictMode>
    <FetchConfigProvider>
      <ThemeContextProvider>
        <StyledApp />
      </ThemeContextProvider>
    </FetchConfigProvider>
  </React.StrictMode>
);

