export const CONTROL_COOKIE_NAME = '_cc_control';
export const CORPORATIVE_WEBSITE = 'https://cookie-control.org';
export const FAMOUS_DOMAINS = [
    ".google.es",     // Google y sus servicios relacionados
    ".facebook.es",   // Facebook
    ".twitter.es",    // Twitter
    ".amazon.es",     // Amazon y sus variantes regionales
    ".linkedin.es",   // LinkedIn
    ".instagram.es",  // Instagram
    ".pinterest.es",  // Pinterest
    ".reddit.es",     // Reddit
    ".microsoft.es",  // Microsoft y sus servicios relacionados
    ".yahoo.es",      // Yahoo
    ".apple.es",      // Apple y sus servicios relacionados
    ".netflix.es",    // Netflix
    ".spotify.es",    // Spotify
    ".tumblr.es",     // Tumblr
    ".adobe.es",      // Adobe y sus servicios relacionados
    ".dropbox.es",    // Dropbox
    ".github.es",     // GitHub
    ".slack.es",      // Slack
    ".whatsapp.es",   // WhatsApp
    ".paypal.es",     // PayPal
    ".shopify.es",    // Shopify
    ".wikipedia.org",  // Wikipedia
    ".bing.es",       // Bing
    ".zoom.us",        // Zoom
    ".salesforce.es", // Salesforce
    ".ebay.es",       // eBay
    ".uber.es",       // Uber
    ".airbnb.es",     // Airbnb
    ".snapchat.es",   // Snapchat
    ".vimeo.es",      // Vimeo
    ".dailymotion.es",// Dailymotion
    ".yelp.es",       // Yelp
    ".oracle.es",     // Oracle
    ".ibm.es",        // IBM
    ".google.com",     // Google y sus servicios relacionados
    ".facebook.com",   // Facebook
    ".twitter.com",    // Twitter
    ".amazon.com",     // Amazon y sus variantes regionales
    ".linkedin.com",   // LinkedIn
    ".instagram.com",  // Instagram
    ".pinterest.com",  // Pinterest
    ".reddit.com",     // Reddit
    ".microsoft.com",  // Microsoft y sus servicios relacionados
    ".yahoo.com",      // Yahoo
    ".apple.com",      // Apple y sus servicios relacionados
    ".netflix.com",    // Netflix
    ".spotify.com",    // Spotify
    ".tumblr.com",     // Tumblr
    ".adobe.com",      // Adobe y sus servicios relacionados
    ".dropbox.com",    // Dropbox
    ".github.com",     // GitHub
    ".slack.com",      // Slack
    ".whatsapp.com",   // WhatsApp
    ".paypal.com",     // PayPal
    ".shopify.com",    // Shopify
    ".wikipedia.org",  // Wikipedia
    ".bing.com",       // Bing
    ".salesforce.com", // Salesforce
    ".ebay.com",       // eBay
    ".uber.com",       // Uber
    ".airbnb.com",     // Airbnb
    ".snapchat.com",   // Snapchat
    ".vimeo.com",      // Vimeo
    ".dailymotion.com",// Dailymotion
    ".yelp.com",       // Yelp
    ".oracle.com",     // Oracle
    ".ibm.com",        // IBM
    document.location.hostname, // Dominio actual
    `.${document.location.hostname}`, // Dominio actual

];
