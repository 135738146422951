import { createContext, useEffect, useState } from "react";
import { ConfigModalModel } from "../models/ConfigModalModel";
import { ControlCookieValue } from "../models/types/ControlCookieValue";
import { fakeConfig } from "../utils/fakeConfig";
import { FetchConfigResponse } from "../models/contracts/FetchConfigResponse";
import { useSystemCookies } from "../hooks/useSystemCookies";
import { CategoriesEnum } from "../models/enums/CategoriesEnum";
import { CookieModel } from "../models/CookieModel";


interface FetchConfigContextInterface {
    config: FetchConfigResponse;
    fetchConfig: () => void;
    setConfig: (value: any) => void;
    rejectAllConfigCookies: () => void;
    acceptAllConfigCookies: () => void;
    updateFromControlCookie: () => void;
    trigger: boolean | undefined;
    getCurrentCookies: () => any;
    deleteSystemCookie: (name: string) => void;
    deleteAllSystemCookies: () => void;
    getConfigCookie: () => ControlCookieValue;
    setConfigCookie: (config?: ControlCookieValue) => void;
    createDefaultControlCookie: () => void;
    deleteSystemCookiesByCategory: (category: string) => void;
    deleteCookiesByControlCookie: (controlCookie: ControlCookieValue) => void;
    acceptAllCookies: () => void;
    detectNotCategorizedCookies: (cookieList: any, systemCookies: any) => any;
    updateConfigCookieFromControlCookieValue: (controlCookie: ControlCookieValue) => void;
    rejectAllSystemCookies: () => void;
    getCookiesByCategory: (category: CategoriesEnum) => CookieModel[];
    isFunctionalAllowed: boolean;
    isMarketingAllowed: boolean;
    isAnalyticsAllowed: boolean;
    isEssentialAllowed: boolean;
    isOtherAllowed: boolean;
    setFunctionalAllowed: (value: boolean) => void;
    setMarketingAllowed: (value: boolean) => void;
    setAnalyticsAllowed: (value: boolean) => void;
    setEssentialAllowed: (value: boolean) => void;
    setOtherAllowed: (value: boolean) => void;
    setAccepted: (value: boolean) => void;
    isAccepted: boolean;
}

export const FetchConfigContext = createContext({} as FetchConfigContextInterface);

export const FetchConfigProvider = ({ children }: any) => {
    const [config, setConfig] = useState({} as FetchConfigResponse);
    const [trigger, setTrigger] = useState(undefined as boolean | undefined);
    const [isFunctionalAllowed, setFunctionalAllowed] = useState(false);
    const [isMarketingAllowed, setMarketingAllowed] = useState(false);
    const [isAnalyticsAllowed, setAnalyticsAllowed] = useState(false);
    const [isEssentialAllowed, setEssentialAllowed] = useState(true);
    const [isOtherAllowed, setOtherAllowed] = useState(false);
    const [isAccepted, setAccepted] = useState(false);
    const {
        getCurrentCookies,
        deleteSystemCookie,
        deleteAllSystemCookies,
        getSystemConfigCookie,
        setConfigCookie,
        createDefaultControlCookie,
        deleteSystemCookiesByCategory,
        deleteCookiesByControlCookie,
        acceptAllCookies,
        detectNotCategorizedCookies,
        rejectAllSystemCookies,
    } = useSystemCookies();

    useEffect(() => {
        setTimeout(() => {
            if (trigger === undefined) {
                return setTrigger(true);
            }
            return setTrigger(!trigger);
        }, 2000);
    }, [trigger]);

    const [firstLoad, setFirstLoad] = useState(false);

    useEffect(() => {
        deleteCookiesByControlCookie(config.cookies as CookieModel[] || [])
    }, [trigger]);

    useEffect(() => {
        if (firstLoad === false) {
            const configCookie = {
                accepted: getSystemConfigCookie().accepted,
                essential: getSystemConfigCookie().essential,
                analytics: getSystemConfigCookie().analytics,
                marketing: getSystemConfigCookie().marketing,
                functional: getSystemConfigCookie().functional,
                other: getSystemConfigCookie().other,
            } as ControlCookieValue;
            setEssentialAllowed(configCookie.essential);
            setFunctionalAllowed(configCookie.functional);
            setMarketingAllowed(configCookie.marketing);
            setAnalyticsAllowed(configCookie.analytics);
            setOtherAllowed(configCookie.other);
            setAccepted(configCookie.accepted);
            setFirstLoad(true);
            return setConfigCookie(configCookie);
        }

        const configCookie = {
            accepted: isAccepted,
            essential: isEssentialAllowed,
            analytics: isAnalyticsAllowed,
            marketing: isMarketingAllowed,
            functional: isFunctionalAllowed,
            other: isOtherAllowed,
        } as ControlCookieValue;
        return setConfigCookie(configCookie);
    },
        [
            isEssentialAllowed,
            isFunctionalAllowed,
            isMarketingAllowed,
            isAnalyticsAllowed,
            isOtherAllowed,
            isAccepted
        ])


    const fetchConfig = async () => {
        try {
            /* const response = await fetch(`${api}/v1/domain/url/${window.location.hostname}`);
    
            const retrievedConfig = await response.json();
            console.log(retrievedConfig.data.config_modal[0]);
            const data: ConfigModalModel = retrievedConfig.data.config_modal[0]; */
            const data = fakeConfig;
            setConfig(data);
        } catch (e) {
            console.log("This domain is not registered in the database");
            console.log(e);
        }
    }

    useEffect(() => {
        fetchConfig();
    }, []);

    const rejectAllConfigCookies = () => {
        return rejectAllSystemCookies();
    }

    const acceptAllConfigCookies = () => {
        return acceptAllCookies();
    }


    const updateFromControlCookie = () => {
        const controlCookie = getSystemConfigCookie();
        setFunctionalAllowed(controlCookie.functional);
        setMarketingAllowed(controlCookie.marketing);
        setAnalyticsAllowed(controlCookie.analytics);
        setEssentialAllowed(true);
    }

    const getCookiesByCategory = (category: CategoriesEnum) => {
        return config.cookies?.filter((c) => c.category === category);
    }


    const value: any = {
        config,
        fetchConfig,
        setConfig,
        rejectAllConfigCookies,
        acceptAllConfigCookies,
        updateFromControlCookie,
        trigger,
        getCurrentCookies,
        deleteSystemCookie,
        deleteAllSystemCookies,
        getSystemConfigCookie,
        setConfigCookie,
        createDefaultControlCookie,
        deleteSystemCookiesByCategory,
        deleteCookiesByControlCookie,
        acceptAllCookies,
        detectNotCategorizedCookies,
        rejectAllSystemCookies,
        setFunctionalAllowed,
        setMarketingAllowed,
        setAnalyticsAllowed,
        setEssentialAllowed,
        isFunctionalAllowed,
        isMarketingAllowed,
        isAnalyticsAllowed,
        isEssentialAllowed,
        isOtherAllowed,
        setOtherAllowed,
        setAccepted,
        isAccepted,
        getCookiesByCategory
    }

    return (
        <FetchConfigContext.Provider value={value}>
            {children}
        </FetchConfigContext.Provider>
    );
};
