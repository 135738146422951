import { createContext, useEffect, useState } from "react";

export const VisibilityContext = createContext({
    popoverVisibility: true,
    setPopoverVisibility: (value: boolean) => { },
    configMenuVisibility: false,
    setConfigMenuVisibility: (value: boolean) => { },
    floatingButtonVisibility: false,
    setFloatingButtonVisibility: (value: boolean) => { }
});



export const VisibilityProvider = ({ children }: any) => {
    const [popoverVisibility, setPopoverVisibility] = useState(false);
    const [configMenuVisibility, setConfigMenuVisibility] = useState(false);
    const [floatingButtonVisibility, setFloatingButtonVisibility] = useState(false);


    const value: any = {
        popoverVisibility,
        setPopoverVisibility,
        configMenuVisibility,
        setConfigMenuVisibility,
        floatingButtonVisibility,
        setFloatingButtonVisibility
    }

    useEffect(() => {
        if (popoverVisibility || configMenuVisibility) {
            setFloatingButtonVisibility(false);
        } else {
            setFloatingButtonVisibility(true);
        }
    }, [popoverVisibility, configMenuVisibility])

    return (
        <VisibilityContext.Provider value={value}>
            {children}
        </VisibilityContext.Provider>
    );
};


