import { CONTROL_COOKIE_NAME } from "../constants/constants";
import { FetchConfigResponse } from "../models/contracts/FetchConfigResponse";

export const fakeConfig: FetchConfigResponse = {
  config_modal: {
    id: 1,
    domain_id: 1,
    info_url: "https://tokenmys.io/politica-de-cookies",
    primary_color: "#684AFF",
    secondary_color: "#684AFF",
    auto_dark_mode: false,
    block_background: false,
    cc_reference: false,
    is_custom_logo: false,
    createdAt: "2021-05-05T12:00:00.000Z",
    updatedAt: "2021-05-05T12:00:00.000Z",
  },
  cookies: [
    // essential
    {
      id: 1,
      name: CONTROL_COOKIE_NAME,
      category: "ESSENTIAL",
      domain: "tokenmys.io",
      expiry: "Un año",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
      description: [
        {
          id: 1,
          createdAt: "2021-05-05T12:00:00.000Z",
          updatedAt: "2021-05-05T12:00:00.000Z",
          expiry: "30 days",
          description:
            "Esta cookie es necesaria para guardar tus preferencias de cookies. No se almacena ninguna información personal.",
        },
      ],
    },
    // functional
    {
      id: 2,
      name: "_ga",
      category: "ANALYTICS",
      domain: "tokenmys.io",
      expiry: "Un año",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
      description: [
        {
          id: 2,
          createdAt: "2021-05-05T12:00:00.000Z",
          updatedAt: "2021-05-05T12:00:00.000Z",
          expiry: "30 days",
          description:
            "Cookie de Google Analytics. Se usa para distinguir a los usuarios.",
        },
      ],
    },
    // analytics
    {
      id: 3,
      name: "_gid",
      category: "ANALYTICS",
      domain: "tokenmys.io",
      expiry: "Un año",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
      description: [
        {
          id: 3,
          createdAt: "2021-05-05T12:00:00.000Z",
          updatedAt: "2021-05-05T12:00:00.000Z",
          expiry: "30 days",
          description:
            "Cookie de Google Analytics. Se usa para limitar el porcentaje de solicitudes.",
        },
      ],
    },
    // advertising
    {
      id: 4,
      name: "_gat",
      category: "MARKETING",
      domain: "tokenmys.io",
      expiry: "Un año",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
      description: [
        {
          id: 4,
          createdAt: "2021-05-05T12:00:00.000Z",
          updatedAt: "2021-05-05T12:00:00.000Z",
          expiry: "30 days",
          description:
            "Cookie de Google Analytics. Se usa para distinguir a los usuarios.",
        },
      ],
    },
    // others
    {
      id: 5,
      name: "_gat_gtag_UA_123456789_1",
      category: "OTHER",
      domain: "tokenmys.io",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
    },
  ],
  popover_data: {
    id: 1,
    language: "es",
    title: "Utilizamos cookies",
    description:
      "Utilizamos cookies para mejorar su experiencia en nuestro sitio web. Al navegar por este sitio web, usted acepta nuestro uso de cookies.",
    information_text: "Más información",
    createdAt: "2021-05-05T12:00:00.000Z",
    updatedAt: "2021-05-05T12:00:00.000Z",
  },
  category_data: [
    {
      id: 1,
      language: "es",
      category: "ESSENTIAL",
      nameSynomym: "Necesarias",
      domainSynomym: "Dominio",
      expirationSynomym: "Expiración",
      descriptionSynomym: "Descripción",
      title: "Cookies esenciales",
      description:
        "Las cookies necesarias son imprescindibles para el correcto funcionamiento de una página web. Por ejemplo, permiten a un usuario registrarse o iniciar sesión. Sin estas cookies, los servicios solicitados por el usuario no se pueden prestar.",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
    },
    {
      id: 2,
      language: "es",
      category: "FUNCTIONAL",
      nameSynomym: "Funcionales",
      domainSynomym: "Dominio",
      expirationSynomym: "Expiración",
      descriptionSynomym: "Descripción",
      title: "Cookies funcionales",
      description:
        "Las cookies funcionales permiten que el sitio web recuerde información ya proporcionada por el usuario y brinde funciones mejoradas y más personalizadas.",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
    },
    {
      id: 3,
      language: "es",
      category: "ANALYTICS",
      nameSynomym: "Analíticas",
      domainSynomym: "Dominio",
      expirationSynomym: "Expiración",
      descriptionSynomym: "Descripción",
      title: "Cookies analíticas",
      description:
        "Las cookies analíticas permiten recopilar información sobre el uso de un sitio web. Por ejemplo, qué páginas son más populares, si hay errores en la página, etc. La información recopilada por estas cookies se utiliza para mejorar el funcionamiento de un sitio web.",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
    },
    {
      id: 4,
      language: "es",
      category: "MARKETING",
      nameSynomym: "Publicitarias",
      domainSynomym: "Dominio",
      expirationSynomym: "Expiración",
      descriptionSynomym: "Descripción",
      title: "Cookies publicitarias",
      description:
        "Las cookies de publicidad permiten recopilar información sobre el uso de un sitio web por parte de los usuarios. Por ejemplo, qué páginas son más populares, si hay errores en la página, etc. La información recopilada por estas cookies se utiliza para mejorar el funcionamiento de un sitio web.",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
    },
    {
      id: 5,
      language: "es",
      category: "OTHER",
      nameSynomym: "Otras",
      domainSynomym: "Dominio",
      expirationSynomym: "Expiración",
      descriptionSynomym: "Descripción",
      title: "Otras cookies",
      description:
        "Las cookies no clasificadas son cookies que estamos procesando para clasificar, junto con los proveedores de cookies individuales.",
      createdAt: "2021-05-05T12:00:00.000Z",
      updatedAt: "2021-05-05T12:00:00.000Z",
    },
  ],

  config_modal_data: {
    id: 1,
    language: "es",
    title: "Preferencias de cookies",
    description:
      "Utilizamos cookies para mejorar su experiencia en nuestro sitio web. Al navegar por este sitio web, usted acepta nuestro uso de cookies.",
    info_url_text: "Más información",
    createdAt: "2021-05-05T12:00:00.000Z",
    updatedAt: "2021-05-05T12:00:00.000Z",
  },
};
